<template>
  <v-card class="card-vacancy">
    <div class="box-title">
      <div class="image">
        <FileImageInstitution
          :fileId="attributeOffer.volunteering.institution.fileId"
          :fileInstitution="attributeOffer.volunteering.institution"
        />
      </div>
      <div class="flex-grow-1">
        <div class="title truncate lines-1">{{ attributeOffer.offerName }}</div>
        <div class="truncate lines-2">por {{ attributeOffer.volunteering.institution.entity.name }}</div>
        <a
          @click="goToVolunteering(attributeOffer.volunteeringId)"
          class="link"
        >
          <span>Conheça o projeto</span>
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.90625 0.761719L5.71484 0.953125C5.57812 1.08984 5.57812 1.28125 5.71484 1.41797L10.582 6.28516H0.328125C0.136719 6.28516 0 6.44922 0 6.61328V6.88672C0 7.07812 0.136719 7.21484 0.328125 7.21484H10.582L5.71484 12.1094C5.57812 12.2461 5.57812 12.4375 5.71484 12.5742L5.90625 12.7656C6.04297 12.9023 6.23438 12.9023 6.37109 12.7656L12.1406 6.99609C12.2773 6.85938 12.2773 6.66797 12.1406 6.53125L6.37109 0.761719C6.23438 0.625 6.04297 0.625 5.90625 0.761719Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    </div>
    <div class="pa-4 flex-grow-1">
      <div class="display-flex align-center">
        <div class="f-size-18 font-weight-bold flex-grow-1">
          {{ attributeOffer.volunteering.fullName }}
        </div>
        &nbsp;
          <Icon
            name="fal fa-globe-americas icon-attribute"
            size="24"
            style="color: #02acb4"
            class="mr-2"
            v-if="attributeOffer.isDistanceOportunityAllowed"
            title="Pode atuar à distância"
          />       
          <Icon
            name="fal fa-users icon-attribute"
            size="24"
            style="color: #02acb4"
            class="mr-2"
            v-if="attributeOffer.isPresentialOportunityAllowed"
            title="Pode atuar presencial"
          />
        <Icon
          name="fal fa-calendar icon-attribute"
          size="24"
          style="color: #02acb4"
          v-if="attributeOffer.volunteeringType.name == 'Pontual'"
          title="Vaga Pontual"
        />
        <Icon
          name="fal fa-sync icon-attribute"
          size="24"
          style="color: #02acb4"
          v-if="attributeOffer.volunteeringType.name == 'Recorrente'"
          title="Vaga Recorrente"
        />
      </div>
      <div class="my-2">
        {{ attributeOffer.function }}
      </div>
      <div v-if="attributeOffer.volunteeringType.name == 'Recorrente'">
        <template
          v-for="recurrentType in attributeOffer.volunteeringRecurrentTypes"
        >
          <div class="box-hour" :key="recurrentType.id">
            <div class="f-size-16 font-weight-bold" style="color: #02acb4">
              {{ getRecurrentWeekHoursText(recurrentType) }}
            </div>
            <div class="f-size-16 font-weight-bold">
              {{ getRecurrentWeekDayText(recurrentType) }}
            </div>
            <div class="text-light">
              {{ getRecurrentWeekTimeText(recurrentType) }}
            </div>
          </div>
          <div
            class="display-flex align-center mt-2 py-2 px-4"
            :key="recurrentType.id"
          >
            <Icon name="fal fa-calendar" size="16" class="mr-2" />
            <span class="mr-2">Termina em:</span>
            <b>{{ getRecurrentLimitDateText(recurrentType) }}</b>
          </div>
        </template>
      </div>
      <div v-else-if="attributeOffer.volunteeringType.name == 'Pontual'">
        <div
          class="hours align-center mb-2"
          v-for="pontualType in attributeOffer.volunteeringPontualTypes"
          :key="pontualType.id"
        >
          <Icon name="fas fa-calendar" size="20" style="color: #adcb4b"/>
          <h5 class="flex-grow-1">{{ getPontualTypeDateText(pontualType) }}</h5>
          <p>{{ getPontualTypeTimeText(pontualType) }}</p>
        </div>
      </div>
    </div>
    <div class="display-flex px-4" :class="avatars.length > 0 ? 'pb-2' : 'pb-5'">
      <v-btn v-if="(attributeOffer.totalOfferAmount - attributeOffer.registrationAmount) > 0" color="secondary" round dark class="flex-grow-1 mx-0 mt-2" @click="$emit('selectOffer', attributeOffer)">
        Inscreva-se
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
      <v-btn v-else disabled depressed round class="flex-grow-1 mx-0 mt-2">          
        Vagas esgotadas
        <Icon name="fal fa-chevron-right" size="16" class="ml-4" />
      </v-btn>
    </div>
      <div class="avatar-group">
        <div class="single-avatar tool" v-for="(avatar, index) in avatars" :key="'avatar'+index" v-if="index < 3">
          <img :src="avatar.url" :alt="avatar.name">
        </div>
        <div class="hidden-avatars" v-if="avatars.length > 3">
          {{ '+' + (avatars.length - 3) }}
        </div>
      </div>
    
    <div class="card-footer">
      <Icon v-if="attributeOffer.address.city" name="fal fa-map-marker-alt" size="16" class="mr-2" />
      <div class="flex-grow-1">
        <span>{{ attributeOffer.address.city }}</span>
      </div>
      <div class="f-size-13 text-light mr-2 ml-2">Inscritos: </div>
      <b class="text-secondary f-size-16">{{
        attributeOffer.registrationAmount
      }} </b>
      <div class="f-size-13 text-light mr-2 ml-2">&nbsp/&nbsp Vagas: </div>
      <b class="text-secondary f-size-16">{{
        attributeOffer.totalOfferAmount - attributeOffer.registrationAmount
      }}</b>
    </div>
  </v-card>
</template>

<script>
import moment from "moment";
import { Routes } from "@/scripts/models/enums/routes.enum";
import FileImageInstitution from "@/components/FileImageInstitution";
import VolunteeringRegistrationOfferService from "@/scripts/services/volunteeringRegistrationOffer.service";
import UserService from "@/scripts/services/user.service";

export default {
  components: {
    FileImageInstitution,
  },
  props: {
    attributeOffer: Object,
    volunteeringAttributeOfferId: Object,
  },
  data() {
    return {
      volunteeringRegistrationOfferService: new VolunteeringRegistrationOfferService(),
      userService: new UserService(),
      volunteeringId: this.volunteeringAttributeOfferId,
      avatars: [],
    }
  },
  async mounted() {
    await this.getAllAvatars(this.volunteeringId)
  },
  methods: {
    getRecurrentWeekHoursText(recurrentType) {
      if (
        recurrentType.minHoursDedication &&
        recurrentType.maxHoursDedication &&
        recurrentType.minHoursDedication != recurrentType.maxHoursDedication
      )
        return `De ${recurrentType.minHoursDedication} a ${recurrentType.maxHoursDedication} Horas Semanais`;

      if (recurrentType.minHoursDedication)
        return `${recurrentType.minHoursDedication} Horas Semanais`;

      if (recurrentType.maxHoursDedication)
        return `${recurrentType.maxHoursDedication} Horas Semanais`;

      return "Não definido";
    },
    getRecurrentWeekDayText(recurrentType) {
      var weekDays = [];
      recurrentType.volunteeringRecurrentWeekDays.forEach((weekDay) => {
        if (weekDay.weekDayName == "Segunda") {
          weekDays.push({ order: 1, name: "Seg" });
        } else if (weekDay.weekDayName == "Terça") {
          weekDays.push({ order: 2, name: "Ter" });
        } else if (weekDay.weekDayName == "Quarta") {
          weekDays.push({ order: 3, name: "Quar" });
        } else if (weekDay.weekDayName == "Quinta") {
          weekDays.push({ order: 4, name: "Quinta" });
        } else if (weekDay.weekDayName == "Sexta") {
          weekDays.push({ order: 5, name: "Sex" });
        } else if (weekDay.weekDayName == "Sábado") {
          weekDays.push({ order: 6, name: "Sab" });
        } else if (weekDay.weekDayName == "Domingo") {
          weekDays.push({ order: 7, name: "Dom" });
        }
      });

      if (weekDays.length == 0) return "Não definido";

      weekDays.sort((prev, next) => prev.order - next.order);

      if (this.weekDayHasGaps(weekDays))
        return this.getRecurrentWeekDayTextWithGaps(weekDays);

      return this.getRecurrentWeekDayTextWithoutGaps(weekDays);
    },
    weekDayHasGaps(weekDays) {
      let hasGap = false;
      let index = 0;
      let weekDayBefore = null;
      while (!hasGap && index < weekDays.length) {
        let weekDay = weekDays[index];
        if (weekDayBefore != null && weekDay.order - 1 != weekDayBefore.order)
          hasGap = true;

        weekDayBefore = weekDays[index];
        index++;
      }

      return hasGap;
    },
    getRecurrentWeekDayTextWithGaps(weekDays) {
      let result = "";
      for (let index = 0; index < weekDays.length; index++) {
        const weekDay = weekDays[index];
        result += weekDay.name;

        if (index + 2 == weekDays.length) {
          result += " e ";
        } else if (index + 1 < weekDays.length) {
          result += ", ";
        }
      }
      return result;
    },
    getRecurrentWeekDayTextWithoutGaps(weekDays) {
      if (weekDays.length == 0) return "Não definido";

      if (weekDays.length > 1)
        return `${weekDays[0].name} à ${weekDays[weekDays.length - 1].name}`;

      return `${weekDays[0].name}`;
    },
    getRecurrentWeekTimeText(recurrentType) {
      if (
        recurrentType.initialTime &&
        recurrentType.finalTime &&
        recurrentType.initialTime != recurrentType.finalTime
      )
        return `${moment(recurrentType.initialTime).format(
          "HH:mm"
        )}h às ${moment(recurrentType.finalTime).format("HH:mm")}h`;
      if (recurrentType.initialTime)
        return `às ${moment(recurrentType.initialTime).format("HH:mm")}h`;
      if (recurrentType.finalTime)
        return `às ${moment(recurrentType.finalTime).format("HH:mm")}h`;
    },
    getRecurrentLimitDateText(recurrentType) {
      return moment(recurrentType.volunteeringDateUntil).format("DD/MM/YYYY");
    },
    goToVolunteering(volunteeringId) {
      this.$router.push({
        name: Routes.app.VolunteeringLP,
        query: { volunteeringId: volunteeringId },
      });
    },
    getPontualTypeDateText(pontualType) {
      return moment(pontualType.volunteeringDate).format('DD/MM/YYYY');
    },
    getPontualTypeTimeText(pontualType) {
      if (pontualType.initialTime
        && pontualType.finalTime
        && pontualType.initialTime != pontualType.finalTime)
        return `${moment(pontualType.initialTime).format('HH:mm')}h às ${moment(pontualType.finalTime).format('HH:mm')}h`;
      if (pontualType.initialTime)
        return `às ${moment(pontualType.initialTime).format('HH:mm')}h`;
      if (recurrentType.finalTime)
        return `às ${moment(pontualType.finalTime).format('HH:mm')}h`;
    },
    async getAllAvatars (AttributeOfferId) {
      const users = []
      const registrations = await this.volunteeringRegistrationOfferService.findAllUsersRegistrationOffersToAttributeOffer(AttributeOfferId)
      for (const item of registrations) {
        const userInformation = await this.userService.getInformation(item.userId)
        const name = userInformation.name.split(' ').join('+')
        users.push({
          id: AttributeOfferId,
          name: userInformation.name,
          url: `https://ui-avatars.com/api/?background=ffffff&name=${name}&color=3377b4`
        })
      }
      this.avatars = users
    },
  },
};
</script>

<style>
</style>