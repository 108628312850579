<template>
  <v-layout row justify-center>
    <DonationStepEditIcons
      ref="modalIconsComponent"
      @close="modalIconsClosed"
    />
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn
          flat
          class="btn btn-white px-5 mt-3 font-sys"
          :class="colorButton"
          large
          v-on="on"
        >
          <!-- Editar agradecimentos -->
          {{ textContent }}
          <i :class="iconButton" :style="{ fontSize: size + 'px' }"></i>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          <h1>Agradecimentos</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <v-layout w100 mobile-r-column d-flex mobile-modal-campaign>
            <v-flex
              md6
              xs12
              class="pt-4 border-horizontal display-flex f-d-column"
            >
              <v-btn
                flat
                round
                large
                color="white"
                class="d-flex ml-auto p-425-0-25 btn btn-primary px-5 mb-2"
                @click="create"
                :disabled="selectedAppreciationId == 0"
              >
                <i class="far fa-plus mr-2"></i>Nova faixa
              </v-btn>
              <div class="list-scroll">
                <div
                  class="box-step-modal"
                  :class="{
                    active: selectedAppreciationId === appreciation.id,
                  }"
                  @click="selectAppreciation(appreciation.id)"
                  v-for="appreciation in appreciationList"
                  :key="appreciation.id"
                >
                  <div
                    v-if="
                      appreciation.firstValue == null ||
                        appreciation.firstValue == ''
                    "
                  >
                    até R${{ appreciation.lastValue }}
                  </div>
                  <div
                    v-else-if="
                      appreciation.lastValue == null ||
                        appreciation.lastValue == ''
                    "
                  >
                    mais de R${{ appreciation.firstValue }}
                  </div>
                  <div v-else>
                    de R${{ appreciation.firstValue }} até R${{
                      appreciation.lastValue
                    }}
                  </div>
                  <span v-html="appreciation.description"></span>
                </div>
              </div>
            </v-flex>
            <v-flex
              class="pt-3 box-heart-father"
              md6
              xs12
              :class="{ active: showForm }"
            >
              <div class="list-scroll">
                <div
                  class="bg-news-modal"
                  id="preview"
                  ref="preview"
                  :style="'backgroundImage:' + getImage()"
                >
                  <input
                    type="file"
                    id="upload-appreciation-image"
                    accept="image/*"
                    @change="fileChanged"
                    ref="upload_institution"
                  />
                  <label for="upload-appreciation-image">
                    <i class="fal fa-cloud-upload mr-2"></i>
                    Imagem
                  </label>
                </div>
                <v-flex px-3>
                  <v-layout>
                    <v-flex xs2 mr-2>
                      <InputText
                        ref="campaignAppreciationOrder"
                        type="number"
                        textLabel="Ordem"
                        v-model="campaignAppreciation.order"
                        place-holder="Nº"
                      />
                    </v-flex>
                    <v-flex xs10>
                      <InputText
                        ref="campaignAppreciationName"
                        type="text"
                        textLabel="Nome do agradecimento"
                        v-model="campaignAppreciation.name"
                        place-holder="Nome do agradecimento"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs6 pr-1>
                      <!-- <Input-Text
                        v-model="campaignAppreciation.firstValue"
                        type="text"
                        textLabel="Valor inicial"
                      ></Input-Text>-->
                      <InputMoney
                        type="text"
                        textLabel="Valor inicial"
                        v-model="campaignAppreciation.firstValue"
                        :infoVisible="false"
                        :precision="0"
                      />
                    </v-flex>
                    <v-flex xs6 pl-1>
                      <!-- <Input-Text
                        v-model="campaignAppreciation.lastValue"
                        type="text"
                        textLabel="Valor final"
                      ></Input-Text>-->
                      <InputMoney
                        type="text"
                        textLabel="Valor final"
                        v-model="campaignAppreciation.lastValue"
                        :infoVisible="false"
                        :precision="0"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-radio-group
                      v-model="campaignAppreciation.htmlContent"
                      :key="campaignAppreciation.id"
                    >
                      <v-radio
                        :value="true"
                        label="Conteúdo em texto"
                      ></v-radio>
                      <v-radio
                        :value="false"
                        label="Conteúdo em lista"
                      ></v-radio>
                    </v-radio-group>
                  </v-layout>
                  <v-layout
                    v-if="!campaignAppreciation.htmlContent"
                    class="align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <i
                          :class="campaignAppreciationItem.icon"
                          @click="openIconsModal"
                          style="font-size: 32px"
                          v-on="on"
                        ></i>
                      </template>
                      <span>Icone</span>
                    </v-tooltip>
                    <InputTextArea
                      place-holder="Texto do item"
                      v-model="campaignAppreciationItem.description"
                      class="mx-2"
                      rows="2"
                    />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-switch
                          v-model="campaignAppreciationItem.highlight"
                          style="flex-grow: 0;"
                          v-on="on"
                        ></v-switch>
                      </template>
                      <span>Destacado</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn @click="addItem" icon v-on="on">
                          <i class="fas fa-plus"></i>
                        </v-btn>
                      </template>
                      <span>Adicionar</span>
                    </v-tooltip>
                  </v-layout>
                  <div
                    v-for="appreciationItem in this.campaignAppreciation
                      .campaignAppreciationItems"
                    :key="appreciationItem.description"
                  >
                    <v-divider></v-divider>
                    <v-layout xs12 class="pa-2 align-center">
                      <i :class="appreciationItem.icon"></i>
                      <div class="mx-2" style="flex-grow: 1" v-html="appreciationItem.description">
                      </div>
                      <i class="fas fa-star" v-if="appreciationItem.highlight"></i>
                      <i class="ml-2 fas fa-edit" @click="editItem(appreciationItem)"></i>
                      <v-btn @click="removeItem(appreciationItem)" icon>
                        <i class="fas fa-trash"></i>
                      </v-btn>
                    </v-layout>
                  </div>
                  <v-divider></v-divider>
                  <div class="t-area-heart-step mt-2">
                    <label>Descreva o agradecimento</label>
                    <!-- <InputTextArea v-model="campaignAppreciation.description"></InputTextArea> -->
                    <vue-editor
                      v-model="campaignAppreciation.description"
                      :editor-toolbar="customToolbar"
                    />
                  </div>
                  <v-list
                    v-for="project in projectList"
                    :key="`${project.id}-${campaignAppreciation.id}`"
                  >
                    <v-list-group>
                      <template v-slot:activator>
                        <!-- {{project}} -->
                        <v-list-tile-content>
                          <v-list-tile-title
                            v-text="project.shortName"
                          ></v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                      <v-radio-group v-model="project.donationProductSelected">
                        <v-radio :value="null" label="Selecionar"></v-radio>
                        <v-radio
                          v-for="donationProduct in project.donationProductList"
                          :key="donationProduct.id"
                          :value="donationProduct.id"
                          :label="donationProduct.name"
                        >
                        </v-radio>
                      </v-radio-group>
                      <!-- <v-avatar v-if="donationProduct.file">
                              <img :src="donationProduct.file.path">
                            </v-avatar> -->
                    </v-list-group>
                  </v-list>
                </v-flex>
              </div>
              <v-flex
                class="align-center justify-space-between footer-step-modal"
              >
                <v-btn
                  flat
                  round
                  color="white"
                  @click="showForm = !showForm"
                  class="
                    btn-back-modal
                    d-flex
                    theme--light
                    btn-default
                    px-0
                    ma-0
                    v-btn v-btn--flat v-btn--large v-btn--round
                    theme--light
                    white--text
                  "
                  large
                >
                  <i class="fal fa-arrow-left"></i>
                </v-btn>
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-red px-5"
                  large
                  @click="
                    openConfirmDeleteFileProjectModal(campaignAppreciation.id)
                  "
                  :disabled="selectedAppreciationId == 0"
                  >Excluir</v-btn
                >
                <v-btn
                  flat
                  round
                  color="white"
                  class="d-flex btn btn-primary px-5"
                  large
                  @click="save"
                  :disabled="!isValid"
                  >Salvar</v-btn
                >
              </v-flex>
            </v-flex>
          </v-layout>
          <AucAlert
            ref="confirmationComponent"
            title="Você tem certeza que deseja excluir esse agradecimento?"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="remove(selectedAppreciationId)"
          />
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import CampaignAppreciation from "@/scripts/models/campaignAppreciation.model";
import CampaignService from "@/scripts/services/campaign.service";
import CampaignAppreciationDonationProduct from "@/scripts/services/campaignAppreciationDonationProduct.service";
import { VueEditor } from "vue2-editor";
import CampaignAppreciationItem from "@/scripts/models/campaignAppreciationItem.model";
import DonationStepEditIcons from "@/components/DonationStepEditIcons.vue";

export default {
  components: {
    AucAlert,
    VueEditor,
    DonationStepEditIcons,
  },
  props: {
    value: Array,
    campaignId: "",
    colorButton: "",
    textContent: "",
    iconButton: null,
    size: null,
    projectList: Array,
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      campaignService: new CampaignService(),
      campaignAppreciation: new CampaignAppreciation(),
      campaignAppreciationItem: new CampaignAppreciationItem(),
      campaignAppreciationDonationProduct:
        new CampaignAppreciationDonationProduct(),
      appreciationList: this.value,
      selectedAppreciationId: null,
      dialog: false,
      showForm: false,
      isSelectingIcon: false,
      iconClass: "",
    };
  },
  computed: {
    isValid() {
      if (this.campaignAppreciation.description) return true;
      return false;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        if (!this.isSelectingIcon) {
          this.create();
        } else {
          this.isSelectingIcon = false;
        }
      }
    },
  },
  methods: {
    addItem(){
      if(this.campaignAppreciationItem.id == 0)
        this.campaignAppreciation.campaignAppreciationItems.push(this.campaignAppreciationItem);
      this.campaignAppreciationItem = new CampaignAppreciationItem();
    },
    removeItem(item){
      this.campaignAppreciation.campaignAppreciationItems = this.campaignAppreciation.campaignAppreciationItems.filter(x => x.icon != item.icon && x.description != item.description);
    },
    editItem(item){
      this.campaignAppreciationItem = item
    },
    openIconsModal() {
      this.$refs.modalIconsComponent.open();
      this.dialog = false;
      this.isSelectingIcon = true;
    },
    modalIconsClosed(value) {
      this.dialog = true;
      if (value != "") {
        this.campaignAppreciationItem.icon = value;
      }
    },
    openConfirmDeleteFileProjectModal(id) {
      this.selectedAppreciationId = id;
      this.$refs.confirmationComponent.open();
    },
    listByCampaignId() {
      this.campaignService.listAppreciationsByCampaignId(
        this.listByCampaignIdCallback,
        this.campaignId
      );
    },
    listByCampaignIdCallback(data) {
      this.appreciationList = data;
      this.$emit("input", data);
    },
    selectAppreciation(appreciationId) {
      let appreciation = this.appreciationList.find(
        (appreciation) => appreciation.id == appreciationId
      );
      this.selectedAppreciationId = appreciationId;
      this.copy(appreciation);
    },
    copy(appreciation) {
      // appreciation.campaignAppreciationDonationProducts = [new CampaignAppreciationDonationProduct()]
      this.campaignAppreciation = new CampaignAppreciation();
      this.campaignAppreciationItem = new CampaignAppreciationItem();
      this.campaignAppreciation.campaignAppreciationDonationProducts =
        appreciation.campaignAppreciationDonationProducts;
      if (
        appreciation.campaignAppreciationDonationProducts &&
        appreciation.campaignAppreciationDonationProducts.length > 0
      ) {
        this.projectList.map((e) => {
          e.donationProductList.map((ee) => {
            appreciation.campaignAppreciationDonationProducts
              .filter((f) => f.donationProductId == ee.id)
              .map((ap) => {
                e.donationProductSelected = ap.donationProductId;
              });
          });
        });
      } else {
        this.projectList.map((e) => {
          e.donationProductSelected = null;
        });
      }
      this.campaignAppreciation.id = appreciation.id;
      this.campaignAppreciation.campaignId = appreciation.campaignId;
      this.campaignAppreciation.firstValue = appreciation.firstValue;
      this.campaignAppreciation.lastValue = appreciation.lastValue;
      this.campaignAppreciation.description = appreciation.description;
      this.campaignAppreciation.htmlContent = appreciation.htmlContent;
      this.campaignAppreciation.campaignAppreciationItems = appreciation.campaignAppreciationItems;
      this.campaignAppreciation.name = appreciation.name;
      this.campaignAppreciation.order = appreciation.order;
      this.campaignAppreciation.file.id = appreciation.file.id;
      this.campaignAppreciation.file.userId = appreciation.file.userId;
      this.campaignAppreciation.file.name = appreciation.file.name;
      this.campaignAppreciation.file.path = appreciation.file.path;
      this.campaignAppreciation.file.size = appreciation.file.size;
      this.campaignAppreciation.file.extension = appreciation.file.extension;
      this.campaignAppreciation.file.origin = appreciation.file.origin;
      this.campaignAppreciation.file.publicId = appreciation.file.publicId;
      if (
        appreciation.file != null &&
        appreciation.file.hasOwnProperty("imageContent")
      ) {
        this.campaignAppreciation.file.imageContent =
          appreciation.file.imageContent;
      }
    },
    create() {
      this.selectedAppreciationId = 0;
      this.campaignAppreciation = new CampaignAppreciation();
    },
    save() {
      this.campaignAppreciation.campaignId = this.campaignId;

      this.campaignService.saveAppreciation(
        this.saveAppreciationCallback,
        this.campaignAppreciation
      );
    },
    saveAppreciationCallback(response) {
      const model = [];
      this.projectList
        .filter((f) => f.donationProductSelected != null)
        .map((e) => {
          model.push({
            donationProductId: e.donationProductSelected,
            campaignAppreciationId: this.campaignAppreciation.id,
          });
        });
      this.campaignAppreciationDonationProduct.save(this.saveCallback, {
        campaignAppreciationDonationProducts: model,
        campaignId: this.campaignId,
        campaignAppreciationId: response.id,
      });
    },
    saveCallback(){
      this.listByCampaignId();
      this.create();
    },
    remove(selectedAppreciationId) {
      this.campaignService.deleteAppreciation(
        this.removeCallback,
        selectedAppreciationId
      );
    },
    removeCallback() {
      this.listByCampaignId();
      this.create();
    },
    getImage() {
      if (
        this.campaignAppreciation == null ||
        this.campaignAppreciation.file == null ||
        !this.campaignAppreciation.file.hasOwnProperty("imageContent")
      )
        return "";
      return "url(" + this.campaignAppreciation.file.path + ")";
    },
    fileChanged(event) {
      var file = event.target.files[0];
      let reader = new FileReader();
      this.campaignAppreciation.file.name = file.name;
      reader.onload = function () {
        this.campaignAppreciation.file.path = reader.result;
        this.campaignAppreciation.file.imageContent = reader.result;
      }.bind(this);
      reader.readAsDataURL(file);
    },
  },
};
</script>
