var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid w-100 mt-5",class:_vm.isModal ? 'aux-cfluid' : ''},[(_vm.isModal)?_c('v-flex',{attrs:{"display-flex":"","flex-column":"","mb-3":"","align-center":"","justify-center":""}},[_c('h3',{staticClass:"mt-3 mb-2 text-xs-center f-size-32 color-theme-texto font-destaque"},[_vm._v("\n      "+_vm._s(_vm.campaignLandingPage.donationProductsTitle)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"wrapper pb-3"},[_c('v-layout',{attrs:{"wrap":"","display-flex":"","justify-center":""}},[(!_vm.isModal)?[_c('button',{staticClass:"scroll-arrow prev",attrs:{"type":"button","id":"agradPrevArrow"}},[_c('Icon',{attrs:{"name":"far fa-chevron-left","size":"16"}})],1),_vm._v(" "),_c('button',{staticClass:"scroll-arrow next",attrs:{"type":"button","id":"agradNextArrow"}},[_c('Icon',{attrs:{"name":"far fa-chevron-right","size":"16"}})],1),_vm._v(" "),_c('slick',{ref:"donationProductsSlick",staticClass:"bg-white",attrs:{"id":"donationProductWrapper","options":_vm.slickDonationProductsOptions}},_vm._l((_vm.donationProductList),function(donationProduct){return _c('div',{key:donationProduct.id,staticClass:"item"},[_c('div',{staticClass:"projeto"},[_c('div',{key:donationProduct.id,staticClass:"box-donation-product"},[_c('div',[_c('img',{attrs:{"src":donationProduct.file.path,"alt":""}}),_vm._v(" "),_c('h2',{staticClass:"color-theme-primaria w-100 notranslate"},[_vm._v("\n                    "+_vm._s(donationProduct.name)+"\n                  ")]),_vm._v(" "),_c('h3',{staticClass:"color-theme-texto notranslate"},[_vm._v("\n                    "+_vm._s(_vm.project.projectTypeId == _vm.ProjectTypes.Ticket
                        ? donationProduct.openValue
                          ? "OUTROS VALORES"
                          : _vm.coinSelected +
                            _vm.FormatterHelper.formatMoney(
                              donationProduct.fixedValue,
                              2
                            )
                        : donationProduct.suggestionValue)+"\n                  ")]),_vm._v(" "),_c('p',{staticClass:"notranslate donation-product-description",domProps:{"innerHTML":_vm._s(donationProduct.description)}})]),_vm._v(" "),_c('div',[(_vm.showDonationButton)?_c('button',{staticClass:"bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text",class:_vm.alreadyExistsInCart({
                        project: _vm.project,
                        donationProduct: donationProduct,
                      })
                        ? 'with-check'
                        : '',attrs:{"type":"button","value":"FormatterHelper.formatMoney(donationProduct.fixedValue, 2) : donationProduct.suggestionValue}}","disabled":_vm.soldOut(donationProduct)},on:{"click":function($event){_vm.openConfirmDonationToDonationProductModal(
                        donationProduct
                      );
                      _vm.$gtag(
                        'send',
                        'event',
                        'projetos carrossel',
                        'Quero doar',
                        donationProduct.suggestionValue
                      );}}},[_c('Icon',{staticClass:"check-circle",attrs:{"name":"fas fa-check","size":"18","colorFont":"#02ACB4"}}),_vm._v("\n                    "+_vm._s(_vm.soldOut(donationProduct)
                        ? "Esgotados"
                        : _vm.campaignLandingPage
                        ? _vm.campaignLandingPage.donationButtonText
                        : "Quero doar")+"\n                  ")],1):_vm._e()])])])])}),0)]:_vm._l((_vm.donationProductList),function(donationProduct){return [_c('div',{key:donationProduct.id,staticClass:"box-donation-product"},[_c('div',[_c('img',{attrs:{"src":donationProduct.file.path,"alt":""}}),_vm._v(" "),_c('h2',{staticClass:"color-theme-primaria",attrs:{"id":"donation-product-name"}},[_vm._v("\n              "+_vm._s(donationProduct.name)+"\n            ")]),_vm._v(" "),_c('h3',{staticClass:"color-theme-texto notranslate"},[_vm._v("\n              "+_vm._s(_vm.project.projectTypeId == _vm.ProjectTypes.Ticket
                  ? _vm.coinSelected +
                    _vm.FormatterHelper.formatMoney(donationProduct.fixedValue, 2)
                  : donationProduct.suggestionValue)+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"notranslate",domProps:{"innerHTML":_vm._s(donationProduct.description)}})]),_vm._v(" "),_c('div',[(_vm.showDonationButton)?_c('button',{staticClass:"bg-theme-primaria px-5 mt-2 v-btn v-btn--flat v-btn--large v-btn--round theme--light white--text",class:_vm.alreadyExistsInCart({
                  project: _vm.project,
                  donationProduct: donationProduct,
                })
                  ? 'with-check'
                  : '',attrs:{"type":"button","disabled":_vm.soldOut(donationProduct)},on:{"click":function($event){_vm.openConfirmDonationToDonationProductModal(donationProduct);
                _vm.$gtag(
                  'send',
                  'event',
                  'Produto de doação',
                  'Produto de doação',
                  'Botão quero doar'
                );}}},[_c('Icon',{staticClass:"check-circle",attrs:{"name":"fas fa-check","size":"18","colorFont":"#02ACB4"}}),_vm._v("\n              "+_vm._s(_vm.soldOut(donationProduct)
                  ? "Esgotados"
                  : _vm.campaignLandingPage
                  ? _vm.campaignLandingPage.donationButtonText
                  : "Quero doar")+"\n            ")],1):_vm._e()])])]})],2)],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }