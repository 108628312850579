import BaseCrudService from "./baseCrud.service";

export default class VolunteeringRegistrationOfferService extends BaseCrudService {

    constructor() {
        super('VolunteeringRegistrationOffer');
    }

    findAllUsersRegistrationOffersToAttributeOffer(volunteeringAttributeOfferId) {
        return this.getRequest(`FindAllUsersRegistrationOffersToAttributeOffer/${volunteeringAttributeOfferId}`);
    }

    findUserRegistrationOffersToAttributeOffer(volunteeringAttributeOfferId) {
        return this.getRequest(`FindUserRegistrationOffersToAttributeOffer/${volunteeringAttributeOfferId}`);
    }

    findUserRegistrationRecurrencyGoogleCalendar(volunteeringAttributeOfferId){
        return this.getRequest(`FindUserRegistrationRecurrencyGoogleCalendar/${volunteeringAttributeOfferId}`);
    }

    findTotalRegistrationAmountByVolunteeringAttributeOfferId(volunteeringAttributeOfferId){
        return this.getRequest(`FindTotalRegistrationAmountByVolunteeringAttributeOfferId/${volunteeringAttributeOfferId}`);
    }

    findTotalRegistrationAmountByVolunteeringId(volunteeringId){
        return this.getRequest(`FindTotalRegistrationAmountByVolunteeringId/${volunteeringId}`);
    }

    registerPontualOffer(pontualTypes) {
        return this.postRequest('RegisterPontualOffer', pontualTypes);
    }

    registerRecurrentOffer(recurrentTypes) {
        return this.postRequest('RegisterRecurrentOffer', recurrentTypes);
    }

}